<template>
    <b-card>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="h6 mb-0">Operações</span>
            <div class="d-flex align-items-center">
                <input-date no-label v-model="filtro.dataOperacao" @blur="filtrar">
                    <template #append>
                        <b-button @click="filtrar" variant="valorem-azul-naval" class="ml-2">
                            <div class="d-flex">
                                <i class="fas fa-search"></i>
                            </div>
                        </b-button>
                    </template>
                </input-date>
            </div>
        </div>
        <valorem-tabela 
            striped
            sem-selecionar-todos 
            v-model="tabela.dados" 
            :colunas="tabela.colunas" 
            :quantidade-itens="tabela.quantidadeItens" 
            ref="tabela" 
            backend-paginado 
            @paginar="listar"
            :por-pagina="5"
        >
            <template #cell(statusOperacao)="dados">
                <b-badge v-if="!dados.value.etapa" pill class="p-2 py-1 w-100" :variant="dados.value.variante">
                    {{dados.value.text}}
                </b-badge>

                <div v-if="dados.value.etapa" class="d-flex">
                    <div class="d-flex align-items-center" v-for="(etapa,i) in etapas" :key="`etapa_${i}`">
                        <hr v-if="i" style="width:25px; border-width: 2px;">
                        <div :class="`retangulo mx-2 bg-${etapa.variante} ${etapa.etapa < dados.value.etapa ? 'checado' : ''}`" v-if="(etapa.etapa != dados.value.etapa)"></div>
                        <div class="mx-2" v-if="(etapa.etapa == dados.value.etapa)">
                            <b-badge class="px-2 py-1" :variant="etapa.variante" pill>{{etapa.text}}</b-badge>
                        </div>
                    </div>
                </div>
            </template>
        </valorem-tabela>
    </b-card>
</template>
<script>
import OperacoesServices from '@/common/services/operacoes/operacoes.service';
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "../../../common/utils/helpers";
import { InputDate } from "@/components/inputs";
import moment from "moment";
export default {
    components : {
        InputDate
    },
    data(){
        return {
            tabela : {
                dados : [],
                colunas : [
                    {key : "numeroBordero", label : 'Borderô'},
                    {key : "sequencialAditivo", label : 'Seq. aditivo'},
                    {key : "numeroAditivo", label : 'Aditivo'},
                    {key : "valorBruto", label : "Total face", formatter : (v) => { if(v) return helpers.formatarValor(v) }},
                    {key : "valorLiquido", label : "Total liquido", formatter : (v) => { if(v) return helpers.formatarValor(v) }},
                    {key : "statusOperacao", label : "Status", formatter : (v) => { if(v) return helpers.getEnum(v) }},
                ],
                quantidadeItens : 0,
                paginaAtual : 0,
                porPagina : 0,
            },
            filtro : {
                dataOperacao : ""
            },
            tipoStatusBordero : helpers.TipoStatusBordero
        }
    },
    mounted(){
        this.filtro.dataOperacao = moment().format("yyyy-MM-DD");
        this.listar();
    },
    methods : {
        filtrar : function(){
          this.$refs.tabela.limpar();
          this.listar();
        },
        listar : function(paginaAtual = 0, porPagina = 5){
            this.$store.dispatch(START_LOADING);
            OperacoesServices.listarOperacoesAditivo(this.filtro, paginaAtual, porPagina).then(res => {
                this.tabela.dados = res.data.data.itens.map(el => {
                    if(!el.statusOperacao && el.numeroBordero != "" && !el.numeroAditivo)
                        el.statusOperacao = "EM_ANALISE";

                    return el;
                });
                this.paginaAtual = paginaAtual;
                this.porPagina = porPagina;
                this.tabela.quantidadeItens = res.data.data.paginacao.totalDeElementos;
            }).finally(() => {
                this.$store.dispatch(STOP_LOADING);
            })
        },
    },
    computed : {
        etapas : function(){
            return this.tipoStatusBordero.filter(el => el.etapa)
        }
    }
}
</script>
<style lang="scss" scoped>
    .retangulo{
        height: 8px;
        width: 17px;
        border-radius: 4px;
    }
    .checado::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        position: relative;
        top: -18px;
    }
    
</style>