<template>
  <div class="border-radius border-valorem-cinza-medio py-2">
    <div class="d-flex flex-column align-items-center">
      <h6 class="mb-0" v-b-tooltip.hover :title="valorTotalFormatado">{{ titulo }}</h6>
      <vue-apex-charts
        type="donut"
        height="120"
        :options="chartOptions"
        :series="series"
      />
      <div class="px-2 rodape">
        <span class="titulo mr-2">Saldo<br />disponivel:</span>
        <span class="valor">{{ valorFormatado }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import helpers from "../../../common/utils/helpers";
export default {
  props: {
    titulo: {},
    tituloPizza: {},
    valor: { default: 0 },
    valorTotal: { default: 0 },
    icone: {},
    corBackground: {},
  },
  components: {
    VueApexCharts,
  },
  data: function () {
    return {
      series: [0, 100],
      chartOptions: {},
    };
  },
  methods: {
    popularSeries: function () {
      let valorFormatado = this.valorFormatado;
      this.chartOptions = {
        chart: {
          type: "donut",
        },
        colors: ["#003A5D", "#999"],
        fill: {
          colors: ["#003A5D", "#F3EEE5"],
        },
        labels: [this.tituloPizza || this.titulo, "Saldo disponivel"],
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (j, w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                if (a == 0 && b == 100) return valorFormatado;
                return helpers.formatarValor(j);
              });
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: "",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      let total = a + b;
                      if (!total) return 0;
                      return `${((a * 100) / total).toFixed(0)} %`;
                    });
                  },
                },
                value: {
                  offsetY: -8,
                  fontSize: 18,
                  color: "#003A5D",
                },
              },
            },
          },
        },
      };
      let valorDiferenca = this.valorTotal - this.valor;
      this.series = [this.valor, !valorDiferenca ? 100 : valorDiferenca];
    },
  },
  watch: {
    valorTotal: {
      handler() {
        this.popularSeries();
      },
      immediate: true,
    },
    valor: {
      handler() {
        this.popularSeries();
      },
      immediate: true,
    },
  },
  computed: {
    valorFormatado: function () {
      return helpers.formatarValor(this.valorTotal - this.valor);
    },
    valorTotalFormatado: function () {
      return helpers.formatarValor(this.valorTotal);
    },
  },
};
</script>
<style lang="scss" scoped>
.border-radius {
  border-radius: 4px;
}
.rodape {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  font-size: 12px !important;
  line-height: 14px;
  .titulo {
    white-space: wrap;
  }
  .valor {
    white-space: nowrap;
    margin-bottom: 0px;
    font-size: 12px !important;
    line-height: 14px;
    font-weight: 800;
  }
}
</style>
