<template>
  <div class="row dashboard">
    <div :class="`col-12 col-md-${hasComunicados ? 5 : 6}`">
      <carteira />
    </div>
    <div :class="`col-12 col-md-${hasComunicados ? 4 : 6}`">
      <acessos />
    </div>
    <div :class="[{ 'col-3': hasComunicados }, { 'd-none': !hasComunicados }]">
      <div class="card-mensageria">
        <mensageria
          @hasComunicados="verificarComunicados"
          @carregou="carregouComunicacao"
        />
      </div>
    </div>

    <div class="col-12 col-md-4 mt-2 mb-2"><remessa class="tabelas-dashboard" /></div>
    <div class="col-12 col-md-8 mt-2 mb-2"><operacoes class="tabelas-dashboard" /></div>
  </div>
</template>
<script>
import Carteira from "./components/Carteira";
import Acessos from "./components/Acessos";
import Operacoes from "./components/Operacoes.vue";
import Remessa from "./components/Remessa.vue";
import Mensageria from "../mensageria/Index.vue";

export default {
  name: "Dashboard",
  components: {
    Carteira,
    Acessos,
    Operacoes,
    Remessa,
    Mensageria,
  },
  data: () => ({
    title: "Dashboard",
    hasComunicados: false,
    isComunicacaoCarregado: false,
  }),
  methods: {
    verificarComunicados(value) {
      value && value.length > 0
        ? (this.hasComunicados = true)
        : (this.hasComunicados = false);
    },
    carregouComunicacao() {
      this.isComunicacaoCarregado = true;
    },
  },
};
</script>
<style lang="scss">
.container-dashboard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.dashboard {
  .nav-tabs .nav-link {
    background-color: transparent;
    border: none;
    margin: 0px;
    padding-left: 0px;
    color: #bac7d1;
    font-weight: 600;
    font-size: 14px;
  }

  .nav-tabs .nav-link.active {
    color: #343a40;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border: none;
  }

  .nav-tabs {
    border: none;
  }
}

.tabelas-dashboard .tabela-valorem.table-responsive {
  height: 380px;
}

.card-mensageria {
  background-color: rgb(255, 255, 255);
  height: 100%;
  border-radius: 4px;
}
</style>
