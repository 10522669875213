import ApiService from "@/common/api/api.service";

const DashboardService = {
  obterPeriodoAnalise(form) {
    var url = `/Dashboard?PeriodoAnalise=${form}`;
    var comercial = localStorage.getItem('usuario_comercial');
    var codigoAgente = localStorage.getItem('usuario_codigo_agente');
    if (comercial) {
      url += `&comercial=${comercial}`;
      url += `&codigoAgente=${codigoAgente}`;
    }
    return ApiService.get(url);
  }
};

export default DashboardService;
