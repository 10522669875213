<template>
    <b-card>
        <div class="d-flex align-items-center justify-content-between mb-3">
            <span class="h6 mb-0">Remessa enviada</span>
            <div class="d-flex align-items-center">
                <input-date no-label v-model="filtro.data" @blur="filtrar">
                    <template #append>
                        <b-button @click="filtrar" variant="valorem-azul-naval" class="ml-2">
                            <div class="d-flex">
                                <i class="fas fa-search"></i>
                            </div>
                        </b-button>
                    </template>
                </input-date>
            </div>
        </div>
        <valorem-tabela 
            striped 
            sem-selecionar-todos 
            v-model="tabela.dados" 
            :colunas="tabela.colunas" 
            :quantidade-itens="tabela.quantidadeItens" 
            ref="tabela" 
            backend-paginado 
            @paginar="listar"
            :por-pagina="5"
        >
            <template #cell(acoes)="dados">
                <div>
                    <b-button                
                        @click="reenviarCancelado(dados.item)"
                        variant="default"
                        class="border btn btn-sm"
                        pill
                    >
                        <i class="fas fa-reply-all px-2 "></i>
                    </b-button>
                </div>
            </template>
        </valorem-tabela>
    </b-card>
</template>
<script>
import OperacoesServices from '@/common/services/operacoes/operacoes.service';
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import helpers from "../../../common/utils/helpers";
import { InputDate } from "@/components/inputs";
import moment from 'moment';
export default {
    components : {
        InputDate
    },
    data(){
        return {
            tabela : {
                dados : [],
                colunas : [
                    {key : "bordero", label : 'Número'},
                    {key : "totalFace", label : "Total face", formatter : (v) => { if(v) return helpers.formatarValor(v) }},
                    {key : "acoes", label : ''},
                ],
                quantidadeItens : 0,
                paginaAtual : 0,
                porPagina : 0,
            },
            filtro : {
                data : "",
            }
        }
    },
    mounted(){
        this.filtro.data = moment().format("yyyy-MM-DD");
        this.listar();
    },
    methods : {
        filtrar : function(){
          this.$refs.tabela.limpar();
          this.listar();
        },
        listar : function(paginaAtual = 0, porPagina = 5){
            this.$store.dispatch(START_LOADING);
            OperacoesServices.listarOperacoesRemessa(this.filtro, paginaAtual, porPagina).then(res => {
                this.tabela.dados = res.data.data.itens;
                this.paginaAtual = paginaAtual;
                this.porPagina = porPagina;
                this.tabela.quantidadeItens = res.data.data.paginacao.totalDeElementos;
            }).finally(() => {
                this.$store.dispatch(STOP_LOADING);
            })
        },
        reenviarCancelado(item) {
            let bordero = { bordero: item.bordero };
            this.$store.dispatch(START_LOADING);
            OperacoesServices.reenviarCancelado(bordero).then((result) => {
                if (result.data.data.ehCheque){
                    this.$router.push({name : "operacoes", params: { tipoAcesso: 'cheque' }})
                    return;
                } 
                this.$router.push({name : "operacoes", params: { tipoAcesso: 'titulos_notas' }})
            }).catch((err) => console.log(err))
            .finally(() => this.$store.dispatch(STOP_LOADING));
        },
    }
}
</script>
<style lang="">
    
</style>