<template>
  <router-link :to="{ path: rota, query: { filtroStatus: rotaFiltroStatus } }">
    <div
      class="item-carteira px-2 border-radius"
      :class="
        corBackground
          ? `bg-valorem-${corBackground} border-valorem-${corBackground} text-white`
          : 'border-valorem-cinza-medio'
      "
    >
      <div class="d-flex align-items-center py-2">
        <i
          :class="`
            ${icone} 
            font-18 
            p-2 
            pr-3 
            ${corBackground ? '' : 'text-valorem-azul-naval'}
          `"
        ></i>
        <div class="d-flex flex-column">
          <small class="titulo" :class="corBackground ? '' : 'text-valorem-cinza-escuro'">
            {{ titulo }}
          </small>
          <h6 class="mb-0" :class="corBackground ? '' : 'text-valorem-grafite'">
            {{ valorFormatado }}
          </h6>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import helpers from "../../../common/utils/helpers";

export default {
  props: {
    titulo: {},
    valor: {},
    icone: {},
    corBackground: {},
    monetario : {
      type: Boolean,
      default: true
    },
    rota: {
      type: String,
      default: ""      
    },
    rotaFiltroStatus: {
      type: Number,
      default: 0
    }
  },
  computed: {
    valorFormatado: function () {
      if(!this.monetario)
        return this.valor
      return helpers.formatarValor(this.valor);
    },
  },
};
</script>
<style lang="scss" scoped>
.border-radius {
  border-radius: 4px;
}
</style>

// .item-carteira .titulo {
  //   height: 40px;
  //   font-size: 90%;
  // }
  
  // @media only screen and (max-width: 1092px) {
  //   .item-carteira {
  //     height: 80px;
  //   }
  // }
  
  // @media only screen and (max-width: 920px) {
  //   .item-carteira {
  //     height: 90px;
  //   }
  
  //   .item-carteira .titulo {
  //     height: 60px;
  //   }
  // }
  
  // @media only screen and (max-width: 768px) {
  //   .item-carteira {
  //     height: initial;
  //   }
  
  //   .item-carteira .titulo {
  //     height: 20px;
  //   }
  // }